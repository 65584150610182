import { io } from "socket.io-client";

const socket = io("https://webtopicsserver.onrender.com/");

socket.on("connect", () => {
  console.log(socket.id);

  const join = document.getElementById("join");
  const create = document.getElementById("create");

  join.addEventListener("click", joinGame);
  create.addEventListener("click", createGame);

  const errors = document.getElementById("errors");
  const er = [];

  function joinGame() {
    const gameCode = document.getElementById("joinGame").value;
    const name = document.getElementById("userName").value;
    er.length = 0;
    if (!gameCode) {
      er.push("Please enter a game code");
    }
    if (!name) {
      er.push("Please enter a name");
    }
    if (er.length > 0) {
      errors.innerHTML = "";
      er.forEach((e) => {
        errors.innerHTML += `<p>${e}</p>`;
      });
      return;
    }
    sessionStorage.setItem("gameCode", gameCode);
    sessionStorage.setItem("name", name);
    sessionStorage.setItem("isCreator", false);
    socket.emit("joinGame", gameCode, name);
    socket.on("nameError", () => {
      errors.innerHTML = "Username already taken";
    });

    socket.on("playerJoined", (gameCode) => {
      window.location.href = "/lobby/?" + gameCode;
    });
    socket.on("gameError", (error) => {
      console.log(error);
    });
  }

  function createGame() {
    const gameCode = document.getElementById("createGame").value;
    const name = document.getElementById("userName").value;
    er.length = 0;
    if (!gameCode) {
      er.push("Please enter a game code");
    }
    if (!name) {
      er.push("Please enter a name");
    }
    if (er.length > 0) {
      errors.innerHTML = "";
      er.forEach((e) => {
        errors.innerHTML += `<p>${e}</p>`;
      });
      return;
    }
    sessionStorage.setItem("gameCode", gameCode);
    sessionStorage.setItem("name", name);
    sessionStorage.setItem("isCreator", true);
    socket.on("gameError", (error) => {
      console.log(error);
      errors.innerHTML = error;
    });
    socket.emit("createGame", gameCode, name);
    socket.on("playerJoined", (gameCode) => {
      window.location.href = "/lobby/?" + gameCode;
    });
  }
});
