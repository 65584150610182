import gsap from "gsap";

const tl = gsap.timeline({ paused: true });

tl.from("h1", { duration: 1, y: -50, opacity: 0, ease: "power3.inOut" })
  .from("#errors", { duration: 0.5, opacity: 0, y: 10, ease: "power2.inOut" })
  .from("label, input", {
    duration: 0.7,
    y: 20,
    opacity: 0,
    ease: "bounce.out",
    stagger: 0.2,
  })
  .from("hr", {
    duration: 0.5,
    scaleX: 0,
    transformOrigin: "left",
    ease: "elastic.out(1, 0.3)",
  });

tl.play();
